import memo from 'memoizee'

export const addTagsToTitle = memo(linkObj => {
  return {
    ...linkObj,
    searchTitle:
      linkObj.label +
      ' ' +
      (linkObj.tags ? linkObj.tags.map(tag => tag.label).join(' ') : ''),
  }
})

export const addTagsToTitleForArrayOfLinks = memo(arrayOfLinks => {
  return arrayOfLinks.map(addTagsToTitle)
})
