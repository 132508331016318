import React from 'react'
import SignUp from './SignUp'
import { Link } from 'gatsby'
import './Footer.scss'

const Footer = () => (
  <footer>
    <div className="Footer_container">
      <ul>
        <li>
          <a href="https://twitter.com/webdesignrepo">Twitter</a>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <a href="https://twitter.com/webdesignrepo">Suggest a link</a>
        </li>
        <li>
          <a href="https://sponsorstack.io/s/webdesignrepo">Sponsor</a>
        </li>
      </ul>
      <SignUp isSmall />
    </div>
  </footer>
)

export default Footer
