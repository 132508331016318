import React from 'react'
import './SignUpSuccess.scss'

const SignUpSuccess = () => (
  <>
    <p className="SignUpSuccess_heading">Success!</p>
    <p className="SignUpSuccess_text">
      We'll send you cool stuff to your email
    </p>
  </>
)

export default SignUpSuccess
