import memo from 'memoizee'
import { getSearchParams, addTagsToTitleForArrayOfLinks } from './'

const filterSearchResults = memo(function (allLinks, searchParams) {
  const results = allLinks.filter(link => {
    return searchParams.every(word => {
      return link.searchTitle.toLowerCase().includes(word)
    })
  })
  return results
})

const removeDuplicateResults = results => {
  let cache = {}
  const resultsWithoutDuplicates = results.reduce((acc, currentResult) => {
    // If no key in cache exists (key is the url)
    // then add to the cache and add to the accumulator
    if (!cache[currentResult.link]) {
      cache[currentResult.link] = true
      acc.push(currentResult)
    }
    return acc
  }, [])

  console.log(cache, resultsWithoutDuplicates)

  return resultsWithoutDuplicates
}

export const getSearchResults = (newLinks, vaultLinks) => {
  const allLinks = addTagsToTitleForArrayOfLinks([...newLinks, ...vaultLinks])

  const searchParams = getSearchParams()

  if (!searchParams) {
    return []
  }

  const results = filterSearchResults(allLinks, searchParams)

  const resultsWithoutDuplicates = removeDuplicateResults(results)

  return resultsWithoutDuplicates
}
