import React, { useRef } from 'react'
import { navigate } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import './SearchBar.scss'

const SearchBar = () => {
  const inputRef = useRef()

  const search = () => {
    const arrayOfSearchTerms = inputRef.current.value.split(' ')

    arrayOfSearchTerms.forEach(searchTerm => {
      trackCustomEvent({
        category: 'Search',
        action: 'Enter',
        label: searchTerm,
      })
    })

    navigate(`/search?terms=${arrayOfSearchTerms.join(',')}`)
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      search()
    }
  }

  return (
    <>
      <input
        className="SearchBar"
        ref={inputRef}
        onKeyDown={handleKeyDown}
        aria-label="Search"
        placeholder="search"
      />
      <button className="SearchBar_button" onClick={search}>
        Search
      </button>
    </>
  )
}

SearchBar.propTypes = {}

export default SearchBar
