import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ title }) => (
  <Helmet>
    <title>{title} | webdesignrepo</title>
    <meta
      name="description"
      content="New links every day to the latest dev and design. Plus a vault of the best
      resources for design and development."
    />
  </Helmet>
)

export default Seo
