export const getWindow = () => {
  if (typeof window !== `undefined`) {
    return window
  }
  return {
    location: {},
    localStorage: {
      getItem: () => undefined,
      setItem: () => undefined,
    },
    history: {
      pushState: () => undefined,
    },
    innerWidth: 1000,
    document: {
      body: {
        scrollTop: () => undefined,
      },
      querySelectorAll: () => undefined,
    },
  }
}
