import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { ArrowRight } from './Icons'
import { isEmail, getWindow } from '../utils'
import SignUpSuccess from './SignUpSuccess'
import SignUpFailure from './SignUpFailure'
import Spinner from './Spinner'
import './SignUp.scss'

const SignUp = ({
  isFocusable,
  archive,
  isSmall,
  isVisibleOnMobile = true,
}) => {
  const [hasError, setHasError] = useState(false)
  const [subscribeSuccess, setSubscribeSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const inputRef = useRef()

  const hpRef = useRef()

  const onClick = () => {
    const { value } = inputRef.current

    if (isEmail(value)) {
      setIsLoading(true)

      let body = `email=${value}`

      // Honeypot trap for bots
      if (hpRef && hpRef.current && hpRef.current.value !== '') {
        body = body + '&hp=true'
      }

      fetch('https://wdricons.azurewebsites.net/api/sendyApi', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
      })
        .then(response => {
          setSubscribeSuccess(true)
          getWindow().localStorage.setItem('wdr_subscribed', true)
          setIsLoading(false)
        })
        .catch(error => {
          setSubscribeSuccess(false)
          setIsLoading(false)
        })
    } else {
      setHasError(true)
    }
  }

  const handleKeyDown = e => {
    if (hasError) {
      setHasError(false)
    }
    if (e.keyCode === 13) {
      onClick()
    }
  }

  const [isSubbed] = useState(
    getWindow().localStorage.getItem('wdr_subscribed')
  )

  if (isSubbed) {
    return null
  }

  return (
    <div
      className={classNames('SignUp', {
        'SignUp--archive': archive,
        'SignUp--small': isSmall,
        'SignUp--isVisibleMobile': isVisibleOnMobile,
      })}
      aria-live="assertive"
    >
      {subscribeSuccess === null && (
        <>
          {!isSmall && (
            <p className="SignUp_heading">Get daily updates in your emails</p>
          )}
          {!isSmall && (
            <p className="SignUp_subheading">
              Join two thousand other developers
            </p>
          )}
          {isSmall && (
            <p className="SignUp--small_heading">Join the newsletter</p>
          )}
          <div className="SignUp_bottom">
            <p aria-live="assertive" id="SignUp_error" className="SignUp_error">
              {hasError && 'Email is invalid'}
            </p>
            <input tabIndex="-1" ref={hpRef} className="SignUp_hp" />
            <div className="SignUp_form">
              <input
                className="SignUp_input"
                tabIndex={isFocusable ? 0 : -1}
                placeholder="email"
                aria-label="Email address"
                aria-describedby="SignUp_error"
                ref={inputRef}
                onKeyDown={handleKeyDown}
              />
              <button
                className="SignUp_submit"
                aria-label="Sign up"
                tabIndex={isFocusable ? 0 : -1}
                onClick={onClick}
              >
                {isLoading ? <Spinner /> : <ArrowRight aria-hidden />}
              </button>
            </div>
          </div>
        </>
      )}
      {subscribeSuccess === true && <SignUpSuccess />}
      {subscribeSuccess === false && (
        <SignUpFailure setSubscribeSuccess={setSubscribeSuccess} />
      )}
    </div>
  )
}

export default SignUp
