import React, { useState, useEffect } from 'react'
import SearchBar from './SearchBar'
import { Link } from 'gatsby'
import TextLoop from 'react-text-loop'
import { getWindow } from '../utils'
import './Header.scss'

const Header = () => {
  const [visits] = useState(getWindow().localStorage.getItem('wdr_visits'))

  useEffect(() => {
    const visits = getWindow().localStorage.getItem('wdr_visits')
    getWindow().localStorage.setItem('wdr_visits', Number(visits || 0) + 1)
  }, [])

  return (
    <header>
      <div className="Header_logoWrap">
        <h1>
          <Link to="/">webdesignrepo</Link>
        </h1>
        {visits < 8 && (
          <>
            <span className="Header_dailyLinksText">Daily links on</span>
            <div className="Header_textLoop">
              <TextLoop interval={2600}>
                <span>frontend</span>
                <span>React</span>
                <span>Javascript</span>
                <span>CSS</span>
                <span>animation</span>
                <span>development</span>
              </TextLoop>
            </div>
          </>
        )}
      </div>
      <SearchBar />
    </header>
  )
}

export default Header
