import React from 'react'
import './SignUpFailure.scss'

const SignUpFailure = ({ setSubscribeSuccess }) => {
  const onClick = () => setSubscribeSuccess(null)

  return (
    <>
      <p className="SignUpFailure_heading">Something went wrong</p>
      <button className="SignUpFailure_retry" onClick={onClick}>
        Try again
      </button>
    </>
  )
}

export default SignUpFailure
