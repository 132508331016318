import { useEffect } from 'react'
import { getWindow } from './'

function useScrollTop() {
  useEffect(() => {
    getWindow().document.body.scrollTop = 0
  }, [])
}

export { useScrollTop }
